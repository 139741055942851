<template>
  <div class="loginSystem">
    <div class="loginForm">
      <van-form @submit="submitForm">
        <van-cell-group inset>
          <van-field v-model.trim="formInfo.mobile" name="pattern" placeholder="手机号" maxlength="11" clearable
            :rules="[{ pattern, message: '请填写正确的手机号码' }]" />
          <van-field v-model.trim="formInfo.verifyCode" name="验证码" placeholder="验证码" maxlength="6"
            :rules="[{ required: true, message: '请填写验证码' }]">
            <template #button>
              <van-button size="small" type="primary" @click="sendVerifyCode" :disabled="leftSendTime > 0">{{
                leftSendTime > 0 ? `${leftSendTime}后重新发送` : "发送验证码"
              }}</van-button>
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin: 16px 66px">
          <van-button block type="primary" native-type="submit" :disabled="submitType">
            授权绑定
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, reactive, ref } from "vue";
import * as dd from "dingtalk-jsapi";
import { showFailToast, showSuccessToast } from "vant";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();

const pattern = /^1[3-9]\d{9}$/;
let leftSendTime = ref(0);
let submitType = ref(false);
let formInfo = reactive({ code: "", mobile: "", verifyCode: "" });

dd.ready(function () {
  // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
  dd.runtime.permission.requestAuthCode({
    corpId: "ding908e4ca4070ff45435c2f4657eb6378f",
    onSuccess: function (result) {
      formInfo.code = result.code;
      setTimeout(() => {
        AccountBinding();
      });
    },
    onFail: function (err) {
      console.log(err, "----err");
    },
  });
});

async function AccountBinding(type = false) {
  await proxy.$Service.AccountBinding.bind(formInfo)
    .then((res) => {
      if (res.success) {
        showSuccessToast(res.sub_data.msg);
        sessionStorage.setItem("AUTH_TOKEN", res.sub_data.tokenInfo.token);
        sessionStorage.setItem("MENU_LIST", JSON.stringify(res.sub_data.menu));
        sessionStorage.setItem("AUTH_LIST", JSON.stringify(res.sub_data.auth));
        setTimeout(() => {
          router.push("/tabbarIndex");
        });
      } else {
        if (type) {
          setTimeout(() => {
            router.push("/latchingPage");
          });
        } else {
          showFailToast(res.message);
        }
      }
      submitType.value = false;
    })
    .catch((err) => {
      submitType.value = false;
      let message = "请求失败！请检查网络";
      showFailToast(message);
      console.log(err, message);
    });
}

function submitForm() {
  submitType.value = true;
  AccountBinding(true);
}
async function sendVerifyCode() {
  leftSendTime.value = 60;
  setSendTimeLeft();
  let message = "请求失败！请检查网络";
  await proxy.$Service.AccountBinding.sendVerifyCode(formInfo)
    .then((res) => {
      if (res.success) {
        showSuccessToast("发送成功");
      } else {
        leftSendTime.value = 0;
        showFailToast(res.message);
      }
    })
    .catch((err) => {
      leftSendTime.value = 0;
      showFailToast(message);
      console.log(err, message);
    });
}
function setSendTimeLeft() {
  if (leftSendTime.value > 0) {
    leftSendTime.value -= 1;
    setTimeout(() => {
      setSendTimeLeft();
    }, 1000);
  }
}
</script>

<style lang='scss' scoped>
.loginSystem {
  padding: 1px;

  .loginForm {
    width: 88%;
    margin: 0 auto;
    margin-top: 30vw;
  }
}
</style>