import { createRouter, createWebHistory } from 'vue-router'
import loginSystem from '../views/loginSystem'

const routes = [
  {
    path: '/',
    name: 'loginSystem',
    component: loginSystem
  },
  {
    path: '/latchingPage',
    name: 'latchingPage',
    component: () => import('../views/latchingPage'),
  },
  {
    path: '/tabbarIndex',
    name: 'tabbarIndex',
    component: () => import('../views/tabbarView/tabbarIndex'),
    children: [
      {
        path: 'dataLargeScreen',
        name: 'dataLargeScreen',
        meta: {
          title: '数据大屏'
        },
        component: () => import('../views/tabbarView/dataLargeScreen')
      },
      {
        path: 'phoneFeePlatform',
        name: 'phoneFeePlatform',
        meta: {
          title: '话费平台'
        },
        component: () => import('../views/tabbarView/phoneFeePlatform')
      },
      {
        path: 'extensionManagement',
        name: 'extensionManagement',
        meta: {
          title: '推广管理'
        },
        component: () => import('../views/tabbarView/extensionManagement')
      },
    ]
  },
  {
    path: '/bluerecharge/transferList',
    name: 'transferAudit',
    meta: {
      title: '转账审核'
    },
    component: () => import('../views/phoneFeePlatformList/transferAudit'),
  },
  {
    path: '/blbusiness/channelConversion',
    name: 'channelConversion',
    meta: {
      title: '推广渠道投放管理'
    },
    component: () => import('../views/popularizeManagement/channelConversion'),
  },
  {
    path: '/blbusiness/productionMonitoring',
    name: 'productionMonitoring',
    meta: {
      title: '推广监控'
    },
    component: () => import('../views/popularizeManagement/productionMonitoring'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
