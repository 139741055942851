import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vant from 'vant'
import 'vant/lib/index.css'

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
const Vue = createApp(App)

import Service from './service/serviceInfo'
Vue.config.globalProperties.$Service = Service


Vue.use(vant).use(store).use(router).mount('#app')
