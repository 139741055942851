import Axios from "axios";

const api = Axios.create({
    timeout: 20000,
});
api.interceptors.request.use(
    async (config) => {
        let _authToken = sessionStorage.getItem('AUTH_TOKEN')
        if (_authToken) {
            config.headers.authorizationToken = _authToken
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
)



export default {
    /****
     * GET请求方式
     * @param {*} url
     * @param {*} params
     */
    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            api({
                url,
                method: "get",
                params: params,
            }).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    },
    getAll(url, params = {}) {
        return api({
            //根据天数获取总订单量
            url,
            method: "get",
            params: params,
        });
    },

    /**提交表单数据
     * @param url
     * @param data
     */
    postJson(url, data = {}) {
        return new Promise((resolve, reject) => {
            api({
                url: url,
                method: 'post',
                data: data,
            }).then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
        })
    },

    postFormData(url, data = {}) {
        let formdata = new FormData();
        for (let key in data) {
            formdata.append(key, data[key]);
        }
        return new Promise((resolve, reject) => {
            api({
                url: url,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formdata,
            }).then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
        })
    }
}