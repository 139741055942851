import apiFn from "./axios";

const $Service = {
    // 获取全局参数
    GetGlobalInfo: {
        // 获取运营商
        getCarrierList: data => apiFn.get(`/pages/common/getCarrierList`, { ...data, showAll: true }),
        // 获取渠道商
        getChannelList: data => apiFn.get(`/pages/common/getChannelList`, { ...data }),
    },
    // 账号绑定
    AccountBinding: {
        // 发送验证码
        sendVerifyCode: data => apiFn.get(`/pages/admin/third/sendVerifyCode`, { ...data, operType: 4 }),
        // 绑定登录
        bind: data => apiFn.postJson(`/pages/admin/third/bind`, { ...data }),
    },
    // 数据大屏数据获取
    LargeScreen: {
        //根据月份获取总订单量
        monthListByCarrier: data => apiFn.getAll(`/bas/pages/report/month/listByCarrier`, {
            ...data,
            otherType: "channel",
            groupBy: "carrierId",
        }),
        //根据天数获取总订单量
        dayListByCarrier: data => apiFn.getAll(`/bas/pages/report/day/listByCarrier`, {
            ...data,
            otherType: "channel",
            groupBy: "carrierId",
        }),
        //根据小时获取总订单量
        hourListByCarrier: data => apiFn.getAll(`/bas/pages/report/hour/listByCarrier`, {
            ...data,
            otherType: "channel",
            groupBy: "carrierId",
        }),
        //根据日期获取总订单量
        dayListByBizType: data => apiFn.getAll(`/bas/pages/report/day/listByBizType`, {
            ...data,
            groupBy: "carrierId",
        }),
    },
    // 话费平台相关
    PhoneFeePlatform: {
        // 转账审核列表
        getPreAppCreditList: data => apiFn.get(`/pages/appbill/getPreAppCreditList`, { ...data }),
        // 转账审核操作
        acceptAddAppCredit: data => apiFn.postFormData(`/pages/appbill/secured/acceptAddAppCredit`, { ...data }),
    },
    // 推广管理相关
    popularizeManagement: {
        //触点失败统计（5分钟周期）
        getClickList: data => apiFn.get(`/bas/pages/report/range/min/listClick`, { ...data }),
        //触点信息弹窗统计（5分钟周期）
        getPopupList: data => apiFn.get(`/bas/pages/report/range/min/listClickPopup`, { ...data }),

    }
}

export default $Service;